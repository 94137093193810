html, body {
	padding: 0;
	margin: 0;
}

.menu {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 120px;
	border-right: 1px solid grey;
}

.top-menu {
	position: fixed;
	top: 0;
	left: 120px;
	right: 0;
	height: 40px;
	border-bottom: 1px solid grey;
}

.top-menu .page-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	margin: 0 5px;
}

.main-content {
	margin-top: 40px;
	margin-left: 120px;
	min-height: calc(100vh - 40px);
	background: #f5f5f5;
}

.menu .navigation a {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 5px;
	text-decoration: none;
	font-weight: bold;
	color: #222;
}

.menu .navigation a:hover {
	color: orange;
}

.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-icons-sharp,
.material-icons-two-tone {
	font-size: inherit;
}